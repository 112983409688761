<template>
  <div>
    <formText :data="{type: 'text', subtype: 'text', value: form[index].label, label: 'Überschrift'}" :callback="setLabel"/>
    <div class="flix-form-group">
      <formTextarea :data="{type: 'text', subtype: 'text', value: form[index].description, label: 'Beschreibung'}" :callback="setDescription"/>
    </div>
    <label class="flix-html-label">Optionen</label>
    <div :key="JSON.stringify(form[index].values)">
      <div class="flix-form-group" v-for="(value, label) in form[index].values" :key="label">
        <div class="flix-form-group">
          <formText :data="{type: 'text', subtype: 'text', value: value.label, label: ''}" :callback="function(data){ setValue(data, label) }"/>
        </div>
        <div>
          <a href="#" @click.prevent="addValue(label)" class="flix-btn flix-btn-xs flix-btn-info"><flixIcon :id='"plus"' /></a>
          <a href="#" @click.prevent="removeValue(label)" class="flix-btn flix-btn-xs flix-btn-danger"><flixIcon :id='"minus"' /></a>
        </div>
      </div>
    </div>
    <formRadio v-if="form[index].subtype !== 'email'" :data="{value: form[index].required, values: [{label: 'Pflichtfeld', value: true},{label: 'kein Pflichtfeld', value: false}]}" :callback="setRequired"/>
  </div>
</template>
<script>
export default {
  components: {
    formTextarea () { return import('@/components/formBuilder/parts/textarea') },
    formText () { return import('@/components/formBuilder/parts/text') },
    formRadio () { return import('@/components/formBuilder/parts/radio') }
  },
  props: {
    form: Array,
    index: Number
  },
  data () {
    return {

    }
  },
  methods: {
    addValue (label) {
      this.form[this.index].values.splice(label + 1, 0, { label: '', value: '' })
    },
    removeValue (label) {
      if (Object.keys(this.form[this.index].values).length === 1) {
        return false
      }

      delete this.form[this.index].values[label]
      this.form[this.index].values = Object.values(this.form[this.index].values)
    },
    setValue (data, label) {
      this.form[this.index].values[label] = { value: data, label: data }
    },
    setRequired (data) {
      this.form[this.index].required = data
    },
    setDescription (data) {
      this.form[this.index].description = data
    },
    setLabel (data) {
      this.form[this.index].label = data
    },
    setSubtype (data) {
      this.form[this.index].subtype = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
